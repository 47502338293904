interface ContentsIndicatorParams {
  num: number;
  focus: number;
  color?: string;
  focusColor?: string;
  row?: boolean;
  gap?: number;
  size?: number;
}

const ContentsIndicator = ({
  num,
  focus,
  color = "#D9D9D9",
  focusColor = "#050C9C",
  row = true,
  gap = 8,
  size = 6,
}: ContentsIndicatorParams) => {
  const items = new Array<number>(num).fill(0);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        gap: `${gap}px`,
      }}
    >
      {items.map((value, index) => (
        <div
          key={index}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: index === focus ? focusColor : color,
            borderRadius: "100%",
          }}
        >
          {" "}
        </div>
      ))}
    </div>
  );
};

export default ContentsIndicator;
