import { useEffect, useRef, useState } from "react";
import Layout from "../layout";
import { useLocation, useNavigate } from "react-router-dom";
import ContentsIndicator from "../components/contentsIndicator";
import { useSwipeable } from "react-swipeable";
import { AnimatePresence, motion, PanInfo } from "framer-motion";

const Landing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {}, [content]);

  const handleClick = () => {
    if (content === 0) setContent(1);
    if (content === 1) {
      const param = location.search;
      navigate(`/${param}`);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (content === 0) setContent(1);
    },
    onSwipedRight: () => {
      if (content === 1) setContent(0);
    },
    trackMouse: true,
  });

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const dragDistance = info.offset.x;
      const dragPercentage = dragDistance / containerWidth;

      if (dragPercentage < -0.2 && content === 0) {
        setContent(1);
      } else if (dragPercentage > 0.2 && content === 1) {
        setContent(0);
      }
    }
  };

  return (
    <Layout>
      <div className="flex flex-col items-center justify-between pt-[40px] pb-[65px] w-full h-full bg-gradient-to-br from-[#FFFEFB] to-[#F2F8FA]">
        <img src="image/miniLogo.png" className="mb-[23px]" width={26} />
        <div
          {...handlers}
          ref={containerRef}
          className="w-full overflow-hidden"
        >
          <motion.div
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.2}
            onDragEnd={handleDragEnd}
            className="w-full"
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={content}
                initial={{ opacity: 0, x: content === 0 ? -300 : 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: content === 0 ? -300 : 300 }}
                transition={{ duration: 0.5 }}
              >
                {content === 0 ? <Content1 /> : <Content2 />}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>
        <div>
          <div className="flex flex-row justify-center space-x-3 mb-[50px]">
            <ContentsIndicator num={2} focus={content} />
          </div>
          <button
            className="w-full min-w-[330px] h-[44px] bg-[#050C9C] text-white rounded-lg text-[16px] font-semibold"
            onClick={handleClick}
          >
            {content === 0 ? "다음" : "요정 시작하기"}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Landing;

const Content1 = () => {
  return (
    <div className="w-full flex flex-col items-center h-[400px]">
      <div className="text-[22px] text-[#3572ef] font-semibold mb-2">
        불필요한 고정지출 있으신가요?
      </div>
      <div className="text-sm text-[#6b6b6b] font-regular tracking-tighter">
        알뜰폰 요금제 정보 제공 플랫폼 요정과 함께 줄여봐요
      </div>
      <div className="relative w-[276px] h-[276px] my-[40px]">
        {/* <div className="border border-[#3572EF] border-dashed rounded-full blur-[1px] w-[276px] h-[276px] "></div> */}
        <img src="image/landing/line1.png" width={276} />
        <img
          src="image/landing/content1.png"
          width={287.75}
          className="absolute top-[17px] left-[4px]"
        />
      </div>
    </div>
  );
};

const Content2 = () => {
  return (
    <div className="w-full flex flex-col items-center h-[400px]">
      <div className="border border-[#3572EF] border-dashed rounded-full w-[256px] h-[256px] flex items-center justify-center mt-[30px]">
        <img src="image/landing/content2.png" width={156} />
      </div>
      <div className="text-[18px] text-[#404040] font-semibold text-center w-[300px] mt-[26px]">
        {"알뜰폰 요금제 정보 플랫폼 "}
        <span className="text-[24px] text-[#3572EF]">요정</span>
        {" 에서"}
        <br />
        전문가와 무료로 상담 받아보세요 !
      </div>
    </div>
  );
};
