import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useGASend } from "../hooks/googleAnalytics";
import { useNASend } from "../hooks/naverAnalytics";
import Main from "../pages/main";
import Test from "../pages/test";
import Welcome from "../pages/welcome";
import NotFound from "../pages/notFound";
import Landing from "../pages/landing";
import User from "../pages/user";
import Find from "../pages/find/find";
import Recommend from "../pages/recommend";
import Content1 from "../pages/find/content1";
import Content2 from "../pages/find/content2";

const RouteTracker = () => {
  const location = useLocation();
  const ga = useGASend();
  const na = useNASend();

  useEffect(() => {
    ga.PageViewTrigger(location);
    na.PageViewTrigger();
  }, [location]);

  return null;
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouteTracker></RouteTracker>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/test" element={<Test />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/recommend" element={<Recommend />} />
        <Route path="/find" element={<Find />} />
        <Route path="/find/1" element={<Content1 />} />
        <Route path="/find/2" element={<Content2 />} />
        <Route path="/user" element={<User />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
