const Header = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row justify-between items-center px-[26px] bg-white h-[75px]">
      <img src="image/miniLogo.png" width={26} />
      <div className="font-extrabold">{title}</div>
      <div className="w-[26px]"></div>
    </div>
  );
};

export default Header;
