import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import NavigationBar from "../../components/navigationBar";
import Layout from "../../layout";

const Find = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="relative w-full h-full">
        <Header title="요금제 알아보기" />
        <div className="border-b-[9px] border-[#f1f1f1]" />
        <div className="h-[83px] bg-gradient-to-b from-[#FAFBF0] to-[#F0F5F7] flex flex-row text-left items-center font-extrabold text-[#3572ef] px-8">
          요금제를 사용하며 궁금했던 부분, <br />
          요정과 함께 알아가요 !
        </div>
        <div className="flex flex-col px-8 py-2">
          <div className="flex flex-row items-center justify-end text-[10px] text-[#B0B0B0] mb-2">
            <img src="image/find/arrowDownUp.png" className="mr-1" width={10} />
            최신순
          </div>
          <div className="flex flex-col space-y-4 pb-[90px]">
            <div
              className="h-[195px] w-full border border-[#d9d9d9] rounded-[10px] divide-y"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                const param = location.search;
                navigate(`/find/1${param}`);
              }}
            >
              <div className="flex flex-row justify-between items-end h-[143px] px-5 pb-3 text-[#333333] text-[18px] bg-[#FFF8F2] rounded-t-[10px]">
                <div className="mb-2">
                  스마트폰
                  <br />
                  어떻게 사는게
                  <br />
                  정답일까요?
                </div>
                <img src="image/find/findContent1.png" width={116} />
              </div>
              <div className="text-[10px] font-extrabold grow flex py-[9px] px-[20px]">
                대리점 출신 팀장님이 말하는,
                <br />
                누구보다 똑똑하게 스마트폰 구입하는 방법
              </div>
            </div>
            <div
              className="h-[195px] w-full border border-[#d9d9d9] rounded-[10px] divide-y"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                const param = location.search;
                navigate(`/find/2${param}`);
              }}
            >
              <div className="flex flex-col justify-between h-[143px] px-5 pt-3 pb-2 text-[#333333] text-[18px] bg-[#E6E7F5] rounded-t-[10px]">
                <div className="flex flex-col items-center w-full justify-center">
                  <img
                    src="image/find/findContent2.png"
                    className=""
                    width={96}
                  />
                </div>
                <div className="text-[#050C9C] leading-5">
                  알뜰폰 VS 성지 비교 !
                  <br />
                  나에게 맞는 곳을 찾아봐요.
                </div>
              </div>
              <div className="text-[10px] font-extrabold grow flex py-[9px] px-[20px]">
                대리점 출신 팀장님이 말하는,
                <br />
                누구보다 똑똑하게 스마트폰 구입하는 방법
              </div>
            </div>
          </div>
        </div>
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default Find;
