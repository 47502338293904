import { useNavigate } from "react-router-dom";
import NavigationBar from "../../components/navigationBar";
import Layout from "../../layout";

const Content2 = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="relative w-full h-full">
        <div className="flex flex-row justify-between items-center p-[26px] bg-white h-[75px]">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src="../image/chevronLeft.png" width={20} />
          </button>
          <div className="font-extrabold">요금제 알아보기</div>
          <div className="w-[26px]"></div>
        </div>
        <div className="border-b-[9px] border-[#f1f1f1]" />
        <div className="px-5 bg-gradient-to-b to-[#FDFEFA] from-[#FAFCFD] pb-[100px] flex flex-col">
          <div className="h-[96px] flex flex-row text-left items-center font-extrabold text-black text-[14px] px-5">
            대리점 출신 팀장님이 말하는, <br />
            누구보다 똑똑하게 스마트폰 구입하는 방법
          </div>
          <div className="flex flex-col bg-white border border-[#cdcdcd] rounded-[10px] py-6 pl-6 space-y-5">
            <div className="flex flex-row space-x-6 items-start">
              <img src="../image/find/findIcon1.png" width={20} />
              <div className="flex flex-col space-y-3">
                <div className="text-[14px] font-extrabold text-[#050c9c]">
                  대리점(성지)에서 구매하는 방법
                </div>
                <div className="text-[12px]">
                  성지에서 구매하면 대리점 커미션의 일부를
                  <br />
                  '보조금으로 받을 수 있어요.
                  <br />
                  하지만 이 보조금은 단통법으로 인해 대리점이
                  <br />
                  과태료를 물을 수 있어서 소비자에게 온라인으로
                  <br />
                  공개가 되지 않아요!
                  <br />
                  대리점에 가서 상담을 받아야 해서 번거롭고
                  <br />
                  이러한 할인들을 잘 모른다면 오히려 단점이
                  <br />될 수 있죠!
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-6 items-start">
              <img src="../image/find/findIcon2.png" width={20} />
              <div className="flex flex-col space-y-3">
                <div className="text-[14px] font-extrabold text-[#050c9c]">
                  대리점(성지) 추천 대상
                </div>
                <div className="text-[12px] font-extrabold">
                  <ul className="list-disc list-inside">
                    <li>가족 결합 할인을 받을 수 있는 경우</li>
                    <li>여러 대리점과 비교할 수 있는 지식이 있는 경우</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-6 items-start">
              <img src="../image/find/findIcon3.png" width={20} />
              <div className="flex flex-col space-y-3">
                <div className="text-[14px] font-extrabold text-[#050c9c]">
                  알뜰폰으로 구매하는 방법
                </div>
                <div className="text-[12px]">
                  알뜰폰은 skt, kt, lg u+의 통신망을 임대해
                  <br />
                  제공하는 서비스로, 정부에서 소비자들의
                  <br />
                  통신비 부담을 줄이기 위해 시행한 사업이에요.
                  <br />
                  이를 3사의 통신망을 제공받아 서비스하기에
                  <br />
                  통화 품질에는 차이가 없고, 정부에서 임대료를
                  <br />
                  결정하기 때문에 저렴한 가격으로 운영이 될 수
                  <br />
                  있는거죠.
                  <br />
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-6 items-start">
              <img src="../image/find/findIcon2.png" width={20} />
              <div className="flex flex-col space-y-3">
                <div className="text-[14px] font-extrabold text-[#050c9c]">
                  알뜰폰 추천 대상
                </div>
                <div className="text-[12px]">
                  <ul className="list-disc list-inside">
                    <li>대리점 할인에 대해서 잘 알지 못하는 사람!</li>
                    <li>자취하는 2030세대</li>
                    <li>대리점 방문이 어려운 경우</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="text-center pr-5 pt-2 text-[14px] font-extrabold text-[#050c9c]">
              <div>아직 잘 모르시겠다구요?</div>
              <div>걱장하지 마세요! 요금제 전문가</div>
              <div>요정이 고객님의 상황에 맞춘</div>
              <div>최적의 구매 방법을 추천해드릴게요!</div>
              <div>요정을 믿고 따라와주세요!</div>
            </div>
          </div>
          <button
            className="bg-[#050c9c] text-white w-full mt-6 h-[44px] rounded-[10px] font-extrabold text-[16px]"
            onClick={() => {
              navigate("/recommend");
            }}
          >
            전문가 요금제 추천 페이지 이동
          </button>
        </div>
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default Content2;
