import { useCallback } from "react";
import ReactGA from "react-ga4";
import { Location } from "react-router-dom";

interface EventParams {
  action: string;
  category: string;
  label: string;
}

export const useGASend = () => {
  /// 페이지 접속 기록 확인
  const PageViewTrigger = useCallback((location: Location) => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, []);

  /// 이벤트 기록 확인
  const EventTrigger = useCallback(
    ({ action, category, label }: EventParams) => {
      ReactGA.event({
        action: action,
        category: category,
        label: label,
      });
    },
    []
  );
  return { PageViewTrigger, EventTrigger };
};
