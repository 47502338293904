import { useState } from "react";
import Modal from "../modal";
import { useSwipeable } from "react-swipeable";
import ContentsIndicator from "../contentsIndicator";

interface ContentsType {
  id: number;
  label: string;
  content: JSX.Element;
}

const AccountModal = ({
  visible,
  closeModal,
}: {
  visible: boolean;
  closeModal: (event: React.MouseEvent) => void;
}) => {
  const [type, setType] = useState<string | null>(null);
  const [select, setSelect] = useState<boolean>(false);

  const [content, setContent] = useState(Number);

  const [zoomImg, setZoomImg] = useState<string | null>(null);
  const [zoomVisible, setZoomVisible] = useState<boolean>(false);

  const handleZoomImg = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    const src = (event.target as HTMLImageElement).src;
    setZoomImg(src);
    setZoomVisible(true);
  };

  const handleCloseZoomImg = () => {
    setZoomVisible(false);
  };

  const lgContents: ContentsType[] = [
    {
      id: 0,
      label: "U+ 어플을 다운로드 후 로그인 해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/lgLogo.png"
            className="mt-28"
            width={80}
          />
        </div>
      ),
    },
    {
      id: 1,
      label:
        "가장 상단에 있는 청구요금을 클릭해, 가장 먼저 뜨는 화면을 캡쳐해 주세요",
      content: (
        <div className="flex flex-row justify-center pt-14 space-x-2">
          <img
            src="image/accountModal/lgView1.png"
            onClick={handleZoomImg}
            width={140}
          />
          <img
            src="image/accountModal/lgView2.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: "스크롤 하여 아래로 내린 후, 이번달 청구서 화면을 캡쳐해 주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5 pt-4">
          <img
            src="image/accountModal/lgView3.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: "이번달 청구서 아래, 서비스별 약정 정보를 캡쳐 해 주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/lgView4.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 4,
      label: "마지막으로 고객별 결합 서비스 요금 상세 내역 까지 캡쳐 해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/lgView5.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
  ];

  const ktContents: ContentsType[] = [
    {
      id: 0,
      label: "KT 어플을 다운로드 후 로그인 해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/ktLogo.png"
            className="mt-28"
            width={80}
          />
        </div>
      ),
    },
    {
      id: 1,
      label:
        "가장 상단에 있는 나의 통신요금을 클릭해, 가장 먼저 뜨는 화면을 캡쳐해 주세요",
      content: (
        <div className="flex flex-row justify-center space-x-2 pt-2">
          <img
            src="image/accountModal/ktView1.png"
            onClick={handleZoomImg}
            width={140}
          />
          <img
            src="image/accountModal/ktView2.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: "요금명세서 > 통합(현재 사용중인 번호)을 클릭해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5 pt-4">
          <img
            src="image/accountModal/ktView3.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: "요금명세서 첫 화면을 캡처해주세요!",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5 pt-4">
          <img
            src="image/accountModal/ktView4.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 4,
      label: "파란 박스 안에 내용을 열어서 하나씩 캡처해주세요!",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/ktView5.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
  ];

  const sktContents: ContentsType[] = [
    {
      id: 0,
      label: "SKT 어플을 다운로드 후 로그인 해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/sktLogo.png"
            className="mt-28"
            width={80}
          />
        </div>
      ),
    },
    {
      id: 1,
      label: "우측 하단 메뉴 버튼을 누르고 '나의 가입 정보'를 찾아주세요.",
      content: (
        <div className="flex flex-row justify-center space-x-2 ">
          <img
            src="image/accountModal/sktView1.png"
            onClick={handleZoomImg}
            width={140}
          />
          <img
            src="image/accountModal/sktView2.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 2,
      label:
        "메뉴 > ‘나의 요금제/부가서비스를 클릭한 후, 첫 화면을 캡처해주세요.",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/sktView3.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 3,
      label:
        "나의 요금제/부가서비스 > 나의 혜택/할인을 클릭한 후, 전 화면을 캡처해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/sktView4.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
    {
      id: 4,
      label:
        "나의 요금제/부가서비스 > 나의 부가상품을 클릭한 후, 전 화면을 캡처해주세요",
      content: (
        <div className="flex flex-row justify-center items-start space-x-5">
          <img
            src="image/accountModal/sktView5.png"
            onClick={handleZoomImg}
            width={140}
          />
        </div>
      ),
    },
  ];

  const [contentNum, setContentNum] = useState(0);

  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => {
      if (contentNum < content - 1) {
        setContentNum(contentNum + 1);
      }
    },
    onSwipedRight: () => {
      if (contentNum > 0) {
        setContentNum(contentNum - 1);
      }
    },
    trackMouse: true,
  });

  const handleTypeChange = (event: React.MouseEvent, label: string) => {
    event.stopPropagation();
    if (label != type) setType(label);
  };

  if (select && type === "lg")
    return (
      <Modal visible={visible}>
        <ZoomModal
          visible={zoomVisible}
          img={zoomImg}
          closeModal={handleCloseZoomImg}
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center"
          onClick={(event) => {
            setType(null);
            closeModal(event);
          }}
        >
          <div
            className="flex flex-col w-[334px] bg-white p-[20px] rounded-[20px]"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="flex flex-row justify-between items-center">
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setSelect(false);
                }}
              >
                <img src="/image/chevronLeft.png" width={20} />
              </button>
              <ContentsIndicator
                focus={contentNum}
                num={lgContents.length}
                size={8}
                gap={8}
                focusColor="#3572EF"
              ></ContentsIndicator>
              <div className="w-[20px] h-[20px]"> </div>
            </div>
            <div
              className="text-[18px] font-extrabold ml-1 my-4"
              onClick={() => {
                if (contentNum != 1) setContentNum(contentNum + 1);
              }}
            >
              요금청구서 확인하기 (LG U+)
            </div>
            {/* 애니메이션 */}
            <div {...handlerSwipe} className="relative w-full overflow-hidden">
              <div
                className={`flex flex-row transition-transform duration-500 ease-out`}
                style={{ transform: `translateX(-${contentNum * 100}%)` }}
              >
                {lgContents.map((value, index) => (
                  <Content key={index} {...value} />
                ))}
              </div>
            </div>
            {/* <Content {...contents[contentNum]} /> */}
          </div>
        </div>
      </Modal>
    );
  else if (select && type === "kt")
    return (
      <Modal visible={visible}>
        <ZoomModal
          visible={zoomVisible}
          img={zoomImg}
          closeModal={handleCloseZoomImg}
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center"
          onClick={(event) => {
            setType(null);
            closeModal(event);
          }}
        >
          <div
            className="flex flex-col w-[334px] bg-white p-[20px] rounded-[20px]"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="flex flex-row justify-between items-center">
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setSelect(false);
                }}
              >
                <img src="/image/chevronLeft.png" width={20} />
              </button>
              <ContentsIndicator
                focus={contentNum}
                num={ktContents.length}
                size={8}
                gap={8}
                focusColor="#3572EF"
              ></ContentsIndicator>
              <div className="w-[20px] h-[20px]"> </div>
            </div>
            <div
              className="text-[18px] font-extrabold ml-1 my-4"
              onClick={() => {
                if (contentNum != 1) setContentNum(contentNum + 1);
              }}
            >
              요금청구서 확인하기 (KT)
            </div>
            {/* 애니메이션 */}
            <div {...handlerSwipe} className="relative w-full overflow-hidden">
              <div
                className={`flex flex-row transition-transform duration-500 ease-out`}
                style={{ transform: `translateX(-${contentNum * 100}%)` }}
              >
                {ktContents.map((value, index) => (
                  <Content key={index} {...value} />
                ))}
              </div>
            </div>
            {/* <Content {...contents[contentNum]} /> */}
          </div>
        </div>
      </Modal>
    );
  else if (select && type === "skt")
    return (
      <Modal visible={visible}>
        <ZoomModal
          visible={zoomVisible}
          img={zoomImg}
          closeModal={handleCloseZoomImg}
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center"
          onClick={(event) => {
            setType(null);
            closeModal(event);
          }}
        >
          <div
            className="flex flex-col w-[334px] bg-white p-[20px] rounded-[20px]"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="flex flex-row justify-between items-center">
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setSelect(false);
                }}
              >
                <img src="/image/chevronLeft.png" width={20} />
              </button>
              <ContentsIndicator
                focus={contentNum}
                num={sktContents.length}
                size={8}
                gap={8}
                focusColor="#3572EF"
              ></ContentsIndicator>
              <div className="w-[20px] h-[20px]"> </div>
            </div>
            <div
              className="text-[18px] font-extrabold ml-1 my-4"
              onClick={() => {
                if (contentNum != 1) setContentNum(contentNum + 1);
              }}
            >
              요금청구서 확인하기 (SKT)
            </div>
            {/* 애니메이션 */}
            <div {...handlerSwipe} className="relative w-full overflow-hidden">
              <div
                className={`flex flex-row transition-transform duration-500 ease-out`}
                style={{ transform: `translateX(-${contentNum * 100}%)` }}
              >
                {sktContents.map((value, index) => (
                  <Content key={index} {...value} />
                ))}
              </div>
            </div>
            {/* <Content {...contents[contentNum]} /> */}
          </div>
        </div>
      </Modal>
    );

  // 통신사 선택
  return (
    <Modal visible={visible}>
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center"
        onClick={(event) => {
          setType(null);
          closeModal(event);
        }}
      >
        <div className="z-10 flex flex-col w-[334px] bg-white p-[20px] rounded-[20px] space-y-2">
          <div className="text-[18px] font-semibold">
            본인이 사용하는 통신사를 알려주세요
          </div>
          <div className="text-[12px]">
            통신사에 따른 요금 청구서 캡쳐 방법을 알려드려요
          </div>
          <div className="flex flex-row space-x-2.5 pb-2 text-[#bcbcbc]">
            <div
              className={`w-[50%] border-2 border-[${
                type === "skt" ? "#3572EF" : "#bcbcbc"
              }] text-[${
                type === "skt" ? "#3572EF" : "#bcdcbc"
              }] h-20 rounded-[10px] flex flex-row items-center justify-center`}
              onClick={(event) => {
                event.stopPropagation();
                handleTypeChange(event, "skt");
              }}
            >
              SKT
            </div>
            <div
              className={`w-[50%] border-2 border-[${
                type === "kt" ? "#3572EF" : "#bcbcbc"
              }] text-[${
                type === "kt" ? "#3572EF" : "#bcdcbc"
              }] h-20 rounded-[10px] flex flex-row items-center justify-center`}
              onClick={(event) => {
                event.stopPropagation();
                handleTypeChange(event, "kt");
              }}
            >
              KT
            </div>
            <div
              className={`w-[50%] border-2 border-[${
                type === "lg" ? "#3572EF" : "#bcbcbc"
              }] text-[${
                type === "lg" ? "#3572EF" : "#bcdcbc"
              }] h-20 rounded-[10px] flex flex-row items-center justify-center`}
              onClick={(event) => {
                event.stopPropagation();
                handleTypeChange(event, "lg");
              }}
            >
              LG U+
            </div>
          </div>
          <button
            style={{
              backgroundColor: `${type == null ? "#d9d9d9" : "#3572EF"}`,
            }}
            className={`h-[45px] rounded-[10px] text-${
              type ? "white" : "black"
            } text-[12px]`}
            onClick={(event) => {
              event.stopPropagation();
              setSelect(true);
              setContentNum(0);
              if (type === "lg") setContent(lgContents.length);
              else if (type === "kt") setContent(ktContents.length);
              else if (type === "skt") setContent(sktContents.length);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AccountModal;

const Content = ({ id, label, content }: ContentsType) => {
  return (
    <div className="flex flex-col min-w-[294px] w-full">
      <div className="flex flex-row mb-5">
        <div className="text-[14.3px] w-[75px] font-semibold text-[#3572ef]">
          Step {id + 1}
        </div>
        <div className="text-[14px] font-extrabold text-pretty">{label}</div>
      </div>
      {content}
    </div>
  );
};

const ZoomModal = ({
  visible,
  img,
  closeModal,
}: {
  visible: boolean;
  img: string | null;
  closeModal: () => void;
}) => {
  if (img === null) return <></>;
  return (
    <Modal visible={visible}>
      <div className="z-10 absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center">
        <div className="w-[75%] min-w-[280px] max-w-[600px] relative">
          <img src={img} width={"100%"}></img>
          <button
            className="absolute top-5 right-5 flex flex-col bg-white p-1 rounded-full border border-black"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              closeModal();
            }}
          >
            <img src="image/closeIcon.svg" width={20}></img>
          </button>
        </div>
      </div>
    </Modal>
  );
};
