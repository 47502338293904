import {
  BsFillHeartFill,
  BsHouseDoorFill,
  BsPersonFill,
  BsSearch,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = "/" + location.pathname.split("/").slice(1, 2);
  console.log(path);

  return (
    <div className="fixed left-0 right-0 bottom-0 w-full h-[76px] bg-white text-[#c4c4c4] grid sm:grid-col-3 sm:grid-cols-[1fr_450px_1fr]">
      <div className="sm:col-start-1 hidden"></div>
      <div className="sm:col-start-2 w-full flex flex-row justify-evenly  border-t pt-4">
        <button
          key={1}
          className={`flex flex-col items-center w-[70px] ${
            !path || path === "/" ? "text-[#000000]" : ""
          }`}
          onClick={() => {
            if (path !== "/") {
              const param = location.search;
              navigate(`/${param}`);
            }
          }}
        >
          <BsHouseDoorFill color="" size="20" />
          <div className="mt-2 text-[8px] text-center">홈</div>
        </button>
        <button
          key={2}
          className={`flex flex-col items-center w-[70px] ${
            path === "/recommend" ? "text-[#000000]" : ""
          }`}
          onClick={() => {
            if (path !== "/recommend") {
              const param = location.search;
              navigate(`/recommend${param}`);
            }
          }}
        >
          <BsFillHeartFill color="" size="20" />
          <div className="mt-2 text-[8px] text-center">요금제 추천</div>
        </button>
        <button
          key={3}
          className={`flex flex-col items-center w-[70px] ${
            path === "/find" ? "text-[#000000]" : ""
          }`}
          onClick={() => {
            if (path !== "/find") {
              const param = location.search;
              navigate(`/find${param}`);
            }
          }}
        >
          <BsSearch color="" size="20" />
          <div className="mt-2 text-[8px] text-center">
            요금제
            <br />
            알아보기
          </div>
        </button>
        <button
          key={4}
          className={`flex flex-col items-center w-[70px] ${
            path === "/user" ? "text-[#000000]" : ""
          }`}
          onClick={() => {
            if (path !== "/user") {
              const param = location.search;
              navigate(`/user${param}`);
            }
          }}
        >
          <BsPersonFill color="" size="20" />
          <div className="mt-2 text-[8px] text-center">마이</div>
        </button>
      </div>
      <div className="sm:col-end-1 hidden"></div>
    </div>
  );
};

export default NavigationBar;
