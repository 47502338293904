import YouTube from "react-youtube";

interface YoutubeViewerProps {
  videoId: string;
  description?: string;
  color?: string;
}

const YoutubeViewer = ({ videoId, description, color }: YoutubeViewerProps) => {
  return (
    <div className={`bg-${color}`}>
      <div className="relative w-full h-0 pb-[56%] bg-black flex items-center justify-center rounded-[10px] overflow-hidden">
        <YouTube
          videoId={videoId}
          opts={{
            width: "100%",
            height: "100%",
          }}
          className="absolute top-0 left-0 bottom-0 right-0"
        />
      </div>
      {description ? (
        <div className="text-2xl font-medium p-2">{description}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default YoutubeViewer;
