import { useNavigate } from "react-router-dom";
import NavigationBar from "../../components/navigationBar";
import YoutubeViewer from "../../components/youtubeViewer";
import Layout from "../../layout";

const Content1 = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="relative w-full h-full">
        <div className="flex flex-row justify-between items-center p-[26px] bg-white h-[75px]">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src="../image/chevronLeft.png" width={20} />
          </button>
          <div className="font-extrabold">요금제 알아보기</div>
          <div className="w-[26px]"></div>
        </div>
        <div className="border-b-[9px] border-[#f1f1f1]" />
        <div className="px-5 bg-gradient-to-b to-[#FDFEFA] from-[#FAFCFD]">
          <div className="h-[96px] flex flex-row text-left items-center font-extrabold text-black text-[14px] px-5">
            대리점 출신 팀장님이 말하는, <br />
            누구보다 똑똑하게 스마트폰 구입하는 방법
          </div>
          <div className="flex flex-col p-2 mx-3 bg-white drop-shadow rounded-[10px]">
            <YoutubeViewer videoId="c8OwVTBdE6s" />
            <div className="pl-4 pt-4 pb-3 space-y-4">
              <div className="flex flex-row items-center">
                <img src="../image/find/findProfile.png" width={23} />
                <div className="text-[12px] font-extrabold grow flex px-3">
                  스마트폰 어떻게 사는게 정답일까요?
                </div>
              </div>

              <div className="text-[12px] tracking-tighter leading-tight">
                스마트폰 구매에 정답은 없지만,
                <br />
                통신 구조를 조금만 이해하면 알뜰하게 구매할 수 있어요!
                <br />
                지금부터 요정과 함께 통신비를 줄이는 방법을 알아볼까요?
              </div>
            </div>
          </div>
        </div>
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default Content1;
