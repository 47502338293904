import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNASend } from "../hooks/naverAnalytics";

/// 사용자 유입 시 거쳐가는 페이지로 유입경로, 접속기기 구분, 등을 결정하는 url parameter를 결정하고
const Welcome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const na = useNASend();

  const [imageNum, setImageNum] = useState(0);

  useEffect(() => {
    if (imageNum > 3) {
      const params = `${location.search}`;

      navigate(`/landing${params}`);
    }
    const timer = setInterval(() => {
      setImageNum(() => imageNum + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [imageNum]);

  useEffect(() => {
    na.PageViewTrigger();
  }, []);
  return (
    <div className="flex items-center justify-center h-dvh bg-gradient-to-br from-[#FFFEFB] to-[#F2F8FA]">
      <img width={204} src={`image/loading/logo${imageNum}.png`}></img>
    </div>
  );
};

export default Welcome;
