import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../components/navigationBar";
import Layout from "../layout";

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Layout>
      <div className="relative flex flex-col w-full h-full items-center justify-center space-y-4">
        <div className="flex flex-row space-x-2 divide-x-2 divide-black text-[20px] font-bold">
          <div className="text-center">
            죄송합니다.
            <br />
            서비스 준비중입니다.
          </div>
        </div>
        <div className="flex flex-row justify-center space-x-4">
          <button
            className="w-40 bg-gray-300 rounded-full py-2 px-4"
            onClick={() => {
              navigate(-1);
            }}
          >
            이전 페이지
          </button>
          <button
            className="w-40 bg-gray-500 rounded-full py-2 px-4 text-white"
            onClick={() => {
              const param = location.search;
              navigate(`/${param}`);
            }}
          >
            메인 페이지
          </button>
        </div>
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default User;
