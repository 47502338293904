import Layout from "../layout";
import YoutubeViewer from "../components/youtubeViewer";

const Test = () => {
  const videoId = "AlirzLFEHUI";

  return (
    <Layout>
      <div>
        <YoutubeViewer
          videoId={videoId}
          description="영상의 간단한 설명이 필요하면 작성하면됨"
          color="white"
        />
        <div>hello</div>
      </div>
    </Layout>
  );
};

export default Test;
