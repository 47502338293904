import Layout from "../layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useNASend } from "../hooks/naverAnalytics";
import NavigationBar from "../components/navigationBar";

const Main = () => {
  const na = useNASend();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Layout>
      <div className="relative h-full flex flex-col">
        <div className="relative flex flex-col p-[26px] bg-white h-[300px] space-y-6 bg-blue-100 bg-gradient-to-b from-[#E7EFFE] to-[#ffffff]">
          <img src="image/miniLogo.png" width={26} />
          <div>
            <div className="text-[24px] font-extrabold text-[#3572EF]">
              요정이
              <br />
              통신비를
              <br />
              추천 해드릴게요!
            </div>
            <div className="text-[16px]">
              요정에서 아낀 통신비 평균{" "}
              <span className="border-b border-black">15,800원</span>
            </div>
          </div>
          <div className="space-x-2">
            <button
              className="px-[12px] py-[4px] rounded-full bg-[#3572EF] text-white text-[12px] font-extrabold"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                const param = location.search;
                navigate(`/recommend${param}`);
              }}
            >
              요금제 추천 받기
            </button>
            <button
              className="px-[12px] py-[4px] rounded-full bg-[#050C9C] text-white text-[12px] font-extrabold"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                const param = location.search;
                navigate(`/find${param}`);
              }}
            >
              요금제 알아보기
            </button>
          </div>
          <img
            src="image/main/content1.png"
            className="absolute right-5 top-[-20px] translate-x-4"
            width={220}
          />
        </div>
        <div className="grow bg-[#D7E3FC] p-[26px] pb-[100px]">
          <div className="w-full bg-white rounded-[20px] py-[16px] px-[20px] flex flex-col">
            <div className="text-[16px] font-extrabold">요정 이용 가이드</div>
            <div className="divide-y divide-[#BCBCBC]">
              <div className="flex flex-row space-x-[20px] mb-3 pt-3">
                <div className="w-[50px] flex justify-center items-center">
                  <img src="image/main/mainIcon1.png" width={36} />
                </div>
                <div className="grow flex flex-col">
                  <div className="text-[13px] font-extrabold">
                    전문가 추천 정보
                  </div>
                  <div className="text-[10px]">
                    통신비를 저렴하게 바꾸고 싶은데 고민이시라면...
                    <br />
                    전문가에게 요금제를 추천받아 보세요!
                  </div>
                </div>
              </div>
              <div className="flex flex-row space-x-[20px] my-3 pt-3">
                <div className="w-[50px] flex justify-center items-center">
                  <img src="image/main/mainIcon2.png" width={36} />
                </div>
                <div className="grow flex flex-col">
                  <div className="text-[13px] font-extrabold">
                    비교 견적 가이드 제공
                  </div>
                  <div className="text-[10px]">
                    통신비 싸게 쓰는 비법이 궁금한가요?
                    <br />
                    요정과 함께 알아봐요!
                  </div>
                </div>
              </div>
              <div className="flex flex-row space-x-[20px] my-3 pt-3">
                <div className="w-[50px] flex justify-center items-center">
                  <img src="image/main/mainIcon3.png" width={36} />
                </div>
                <div className="grow flex flex-col">
                  <div className="text-[13px] font-extrabold">
                    고정지출 절약
                  </div>
                  <div className="text-[10px]">
                    요금제를 사용하여 내가 어떤 요금제가 적합한지
                    <br />
                    확인하며 고정지출을 줄여보아요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default Main;
