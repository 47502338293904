import { useState } from "react";
import NavigationBar from "../components/navigationBar";
import Layout from "../layout";
import DataUsageModal from "../components/modal/dataUsageModal";
import AccountModal from "../components/modal/accountModal";
import Header from "../components/header";
import { useNASend } from "../hooks/naverAnalytics";
import { useGASend } from "../hooks/googleAnalytics";

const Recommend = () => {
  const na = useNASend();
  const ga = useGASend();

  const [accountModalVisible, setAccountModalVisible] =
    useState<boolean>(false);
  const [dataModalVisible, setDataModalVisible] = useState<boolean>(false);

  const handleOpenAccountModal = (event: React.MouseEvent) => {
    na.EventTrigger("click", "Account");
    ga.EventTrigger({ action: "click", category: "click", label: "Account" });
    event.stopPropagation();
    event.preventDefault();
    setAccountModalVisible(true);
  };

  const handleCloseAccountModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAccountModalVisible(false);
  };

  const handleOpenDataModal = (event: React.MouseEvent) => {
    na.EventTrigger("click", "DataUsage");
    ga.EventTrigger({ action: "click", category: "click", label: "DataUsage" });
    event.stopPropagation();
    event.preventDefault();
    setDataModalVisible(true);
  };

  const handleCloseDataModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setDataModalVisible(false);
  };

  return (
    <Layout>
      <div className="relative w-full h-full flex flex-col">
        <Header title="AI 요금제 추천" />
        <div className="border-b-[9px] border-[#f1f1f1]" />
        <div className="h-[143px] bg-gradient-to-b from-[#FAFBF0] to-[#F0F5F7] flex flex-row justify-between items-center px-8">
          <div className="font-extrabold text-[#3572ef] text-[20px]">
            사진 두장을 업로드 하고
            <br />
            <span className="text-[#050C9C]">똑똑한 지출</span>을 시작해봐요!
          </div>
          <img src="image/recommend/recommendContent.png" width={107} />
        </div>
        <div className="border-b-[9px] border-[#f1f1f1]" />
        <div className="w-[100%] flex flex-col p-6 space-y-4">
          <button
            className="w-full h-[88px] bg-[#3572ef] rounded-[10px] text-white flex flex-col items-start justify-between p-4"
            onClick={() => {
              na.EventTrigger("click", "Upload");
              ga.EventTrigger({
                action: "click",
                category: "click",
                label: "Upload",
              });

              // 폼 링크로 이동
              window.location.href = "https://walla.my/v/ZHkWKHDZULe9SNDAqYin";
            }}
          >
            <div className="text-[10px] flex flex-col text-left">
              <div>
                스마트폰 전문가 요정과 함께
                <br />
                무료로 컨설팅 받으러 가기
              </div>
            </div>
            <div className="text-[16px] font-semibold">
              사진 업로드 하고 컨설팅 받기
            </div>
          </button>
          <div className="flex flex-row space-x-4">
            <button
              className="w-[50%] h-[88px] bg-[#D4EAF7] rounded-[10px] flex flex-row items-center justify-between p-4"
              onClick={handleOpenDataModal}
            >
              <div className="font-extrabold flex flex-col items-start space-y-1">
                <div className="text-[14px] text-[#050C9C]">데이터 사용량</div>
                <div className="text-[12px] text-[#626262]">
                  캡처 방법 알아보기
                </div>
              </div>
              <img src="image/chevronRight.png" />
            </button>
            <button
              className="w-[50%] h-[88px] bg-[#E6E7F5] rounded-[10px] flex flex-row items-center justify-between p-4"
              onClick={handleOpenAccountModal}
            >
              <div className="font-extrabold flex flex-col items-start space-y-1">
                <div className="text-[14px] text-[#050C9C]">요금 청구서</div>
                <div className="text-[12px] text-[#626262]">
                  캡처 방법 알아보기
                </div>
              </div>
              <img src="image/chevronRight.png" />
            </button>
          </div>
        </div>
        <NavigationBar />
      </div>
      <AccountModal
        visible={accountModalVisible}
        closeModal={handleCloseAccountModal}
      />
      <DataUsageModal
        visible={dataModalVisible}
        closeModal={handleCloseDataModal}
      />
    </Layout>
  );
};

export default Recommend;
