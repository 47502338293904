import { useEffect } from "react";
import Router from "./components/router";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    // React Google Analytics 초기화
    // const gaId = process.env.REACT_APP_GA_ID;
    ReactGA.initialize("G-5DVGL5N8HR"); // react-ga 초기화 및 debug 사용
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
