interface props {
  visible: boolean;
  children: React.ReactNode;
}

const Modal = ({ visible, children }: props) => {
  return <>{visible && children}</>;
};

export default Modal;
